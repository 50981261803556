import Image from 'next/image';
import { useContext, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import AppContext from '../../store/AppContext';
interface Support {
    isDetail?: boolean
}
const Support : React.FC<Support> = ({ isDetail }) => {
    const { branchs } = useContext(AppContext);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenPhone, setIsOpenPhone] = useState(false);
    const [activeId, setActiveId] = useState(branchs && branchs?.length > 0 ? branchs?.[0]?.id : 0);

    function reduceTooLongString(string: string) {
        if (string.length > 10) {
            string = string.slice(0, 10) + "...";
        }

        return string;
    }

    function topFunction() {
        // document.body.scrollTop = 0; // For Safari
        // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
  
      }

    const Item = ({ item }: any) => {
        const ref: any = useRef(null);
        return <div className={`
        text-base 
        ${activeId === item.id ? "font-[550]" : "font-[500]"} 
        text-[#434343] 
        cursor-pointer
        m-0
        mr-2
        `}
            ref={ref}
            onClick={() => {
                ref?.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: 'center' });
                setActiveId(item.id)
            }}
        >
            {reduceTooLongString(item.name) ?? ""}
        </div>
    }

    useEffect(() => {
        const scrollContainer: any = document.getElementById("horizontal-scroll-wrapper");

        scrollContainer?.addEventListener("wheel", (evt: any) => {
            evt.preventDefault();
            scrollContainer.scrollLeft += evt.deltaY;
        });
    }, [])

    return (<>
        {
            isOpen === false && isDetail == false && <Image
                onClick={() => setIsOpen(!isOpen)}
                className={"fixed w-[64px] h-[64px] top-[60%] lg:top-[80%] right-[24px] z-[20] cursor-pointer"}
                src={"/asset/image/support.png"}
                alt={"support"}
                width={64}
                height={64}
            />
        }
        {
            isDetail == true && 
            <>
                <div className="content">
                    <Image
                        className={"cursor-pointer ico-support"}
                        src={"/asset/icon/messenger_icon.png"}
                        alt={"calling"}
                        width={48}
                        height={48}
                    />
                    <p>Chat</p>
                    {
                        <div className="support_popup">
                                {
                                    branchs?.map((item: any) => {
                                        return <Link key={item.id} rel="noopener noreferrer" target='_blank' href={(item.messenger ? item.messenger : "https://m.me/hoaigiangshop")} className='content '>
                                                    <Image
                                                        className={"relative w-[16px] h-[16px]"}
                                                        src={"/asset/icon/messenger_icon.png"}
                                                        alt={"messenger"}
                                                        width={16}
                                                        height={16}
                                                    />
                                                    <span className='text-sm text-[#fff]'>{item.name}</span>
                                                </Link>
                                    })
                                }
                        </div>
                    }
                </div>
                <div className="content">
                    <Image
                        className={"cursor-pointer ico-support"}
                        src={"/asset/icon/ico_calling.svg"}
                        alt={"calling"}
                        width={48}
                        height={48}
                    />
                    <p>Hotline</p>
                    {
                        <div className="support_popup sp_phone">
                                {
                                    branchs?.map((item: any) => {
                                        return <div key={item.id} className='content flex-col'>
                                            <span>{item.name}</span>
                                            {
                                                item.phone.map((phone: string) => {
                                                return <div key={"phone_" + phone} className="flex flex-row gap-[4px] items-center">
                                                    <a href={"tel:"+phone} className='text-sm text-[#fff] flex flex-row gap-[4px] items-center'>
                                                        <Image
                                                            className={"relative w-[16px] h-[16px]"}
                                                            src={"/asset/icon/ico_calling.svg"}
                                                            alt={"calling"}
                                                            width={16}
                                                            height={16}
                                                        />
                                                        {phone}
                                                    </a>
                                                    <Link href={"https://zalo.me/" + phone} rel="noopener noreferrer" target='_blank' key={phone} className=''>
                                                        <Image
                                                            className={""}
                                                            src={"/asset/icon/zalo_icon.png"}
                                                            alt={"zalo"}
                                                            width={16}
                                                            height={16}
                                                        />
                                                    </Link>
                                                </div>
                                                })
                                            }
                                        </div>
                                    })
                                }
                        </div>
                    }
                </div>
                <div className="content border-none">
                    <Image
                        src={"/asset/icon/top-icon.png"}
                        alt="Go Top"
                        onClick={topFunction}
                        className='cursor-pointer ico-support'
                        width={36}
                        height={36}
                    />
                    <p>Lên</p>
                </div>
            </>
        }
        
    </>);
}

export default Support;