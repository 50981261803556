import { PropsWithChildren, Suspense } from 'react'
//import FooterComponent from './Footer'
import HeaderComponent from './Header'
import Support from '../Support';
import dynamic from 'next/dynamic';
import LoadingComponent from '../Loading';

const DynamicFooter = dynamic(() => import('./Footer'));

type TLayoutProps = PropsWithChildren & {
    props?: any,
    className?: string,
    isDetailPage?: boolean,
    isLoading?: boolean,
    componentToolkit?: any
}

const LayoutComponent = ({ children, className, isDetailPage = false, isLoading = true, props, componentToolkit=null }: TLayoutProps) => {
    return (
        <main {...props} className={className}>
            <HeaderComponent />

            <div className='body mt-[50px] md:mt-[64px]'>
                {children}
            </div>
            {/* {
                !isDetailPage && <Support isDetail={false}/>
            } */}
            <div className="wrap-toolkit">
                {componentToolkit}
                <Support isDetail={true}/>
            </div>
            {(!isLoading) && <DynamicFooter />}
            {isLoading == true && <LoadingComponent loading={isLoading} />}
        </main>
    )
}

export default LayoutComponent